import React, { Component } from "react";
import { Link } from "react-router-dom";

class Bonded extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Get Bonded</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            Our bonding department is one of the largest in the State of Arizona. We have an appointment
                            with an A++ Bonding Company. This means you will never lose your bond because the surety
                            company goes out of business. Because they are national, the bonding company can follow you
                            to any state requiring a license bond. We have rapid issue bonds. Which means you can pay
                            and pick up your bond at the same time. No waiting! We send renewals to remind you to renew
                            your license and bond every two years. We offer almost every bond issued in the construction
                            industry including bid, performance, payment and fidelity. The important part of the bond is
                            the Indemnity Agreement (or the fine print). This represents some of the contract you make
                            with the bonding company when you buy the bond. Ours DOES NOT, as many do, require the
                            contractor to remit up to 125% of the bond amount if the company is being litigated and
                            BEFORE guilt is established or judgment issued.
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            We have worked hard to keep our prices low and our approval process requirements simple.
                            Unlike most bond agencies, we have "rapid" issue bonds. This means that any bond $15,000 or
                            less can be issued the same day with no credit or financial evaluation. Our prices are set
                            at every level and will not be adjusted on credit.
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            <p className="boldText">What is a Bond?</p>A bond is a line of credit in the form of a
                            guarantee. A residential contractor license bond guarantees you will complete a job
                            according to the contract, pay your suppliers and subcontractors. A commercial contractor
                            license bond guarantees that you will complete the job to code. Finally, a contractor tax
                            bond guarantees that you will file and pay your Arizona State taxes to the Department of
                            Revenue in a timely fashion.
                        </p>
                    </div>
                    <div className="flow-text">
                        <Link to="/bonded/licensebonds" className="companySetupLinks">
                            Click for LICENSE BONDS
                        </Link>
                        <br />
                        <Link to="/bonded/taxbonds" className="companySetupLinks">
                            Click for TAXPAYER BONDS
                        </Link>
                        <br />
                        <Link to="/bonded/otherbonds" className="companySetupLinks">
                            Click for OTHER BONDS
                        </Link>
                    </div>
                    <br />
                    <div className="flow-text">
                        Call us to get your bond TODAY!
                        <br />
                        (602) 242-2222
                    </div>
                </div>
            </div>
        );
    }
}

export default Bonded;
