import React, { Component } from "react";
import { Table } from "react-materialize";
import { Link } from "react-router-dom";

class TaxBonds extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Taxpayer Bonds</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            The Arizona Department of Revenue requires prime contractors and contractors working on new
                            projects to have a valid Transaction Privilege Tax license number. In order for a contractor
                            to obtain this number they must apply for the license number through the Arizona Department
                            of Revenue and post a taxpayer bond. At GetLicensedBondedInsured.com we make getting the
                            appropriate bond fast, simple and inexpensive.
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            To obtain a new bond, click{" "}
                            <Link to="/store/bonds" className="companySetupLinks">
                                here
                            </Link>
                            . The bond will be issued and mailed within 24 hours. If you need the bond immediately,
                            please visit our office at 6717 N. Black Canyon Hwy, Phoenix, AZ 85015 and you can walk out
                            with the bond.**
                        </p>
                    </div>
                    <h5>Give us a call at (602) 242-2222</h5>
                    <br />
                    <br />
                    <h5 className="collapseTitle">Contractor Tax Bonds</h5>
                    <Table className="centered tables">
                        <thead>
                            <tr>
                                <th data-field="name">Bond Amount</th>
                                <th data-field="id">2 Year Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>$2,000</td>
                                <td>$175.00</td>
                            </tr>
                            <tr>
                                <td>$7,000</td>
                                <td>$582.00</td>
                            </tr>
                            <tr>
                                <td>$17,000</td>
                                <td>$918.00</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        **All license and tax bonds except for the $17,000 and higher tax bonds can be issued
                        immediately. Other bonds have very quick turn around on approvals
                    </p>
                </div>
            </div>
        );
    }
}

export default TaxBonds;
