import React, { Component } from "react";
import { Collapsible, CollapsibleItem } from "react-materialize";
import { Link } from "react-router-dom";

class Faq extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Frequently Asked Questions</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">Click on the questions below for more information.</p>
                    </div>
                    <div className="licenseClassificationsCont">
                        <div className="collapseCont">
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="If I have a felony, can I still apply for a Contractor License?"
                                >
                                    <p>
                                        Although felonies may be an issue, not all are. You can still get a license with
                                        a felony.
                                        <br />
                                        <br />
                                        For information on the list of potential problems
                                        <br />
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://roc.az.gov/sites/default/files/files/sps100_licensing_background_checks.pdf"
                                        >
                                            Click here
                                        </a>
                                        <hr />
                                        If you are concerned about your eligibility you can complete a petition for
                                        review of criminal record.
                                        <br />
                                        <br />
                                        For information on the petition
                                        <br />
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://roc.az.gov/sites/default/files/forms/RC-L-100%20-%20Petition%20for%20Review%20of%20Criminal%20Record.pdf"
                                        >
                                            Click here
                                        </a>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="Do I need to pay all of the licensing process up front? "
                                >
                                    <p>
                                        No, the entire process naturally falls in to 3 steps. <br />
                                        1. Test Prep <br />
                                        2. Company Setup <br />
                                        3. License Application. <br />
                                        You would break up the total cost into 3 payments. This will also help you set
                                        the pace of getting licensed.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="What is the total cost of getting licensed?"
                                >
                                    <p>
                                        Each individuals cost can be different, as it all depends on the license they
                                        are applying for and what services each person needs help with. The cost of
                                        licensing can range from $140-$3,500 depending on your needs.
                                        <br />
                                        <br />
                                        For a more precise quote <br />
                                        <Link to="/store/licensingquote" className="companySetupLinks">
                                            Click here
                                        </Link>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="Can I transfer my license from another state?"
                                >
                                    <p>
                                        No, licenses do not transfer as each State has their own unique requirements.
                                        However, you can have your Trade Exam waived by the Registrar of Contractors if
                                        you have taken a similar exam in last 5 years.
                                        <br />
                                        <br />
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://roc.az.gov/sites/default/files/forms/Waiver%20Request%20Form%20RC-L200G.pdf"
                                        >
                                            Click here for more information
                                        </a>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem className="flow-text" header="How do I prove my experience?">
                                    <p>
                                        The qualifying party would need to list a former employer and/or self employed
                                        experience totaling the years of experience required for that license or more.
                                        Including the detailed description of the work done by the qualifying party.
                                        With that information you would also need a Construction referral, someone who
                                        can verify the years of experience.
                                        <br />
                                        <br />
                                        For more information on experience requirements please visit our
                                        <Link to="/licensed/licenseclassifications" className="companySetupLinks">
                                            {" "}
                                            license classifications
                                        </Link>{" "}
                                        page.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem className="flow-text" header="Can I provide out of state experience?">
                                    <p>
                                        Yes, any experience counts as long as the Registrar of Contractors can verify
                                        it. Even out of country experience can be provided.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="Do I need to be a U.S. Resident and/or provide a Social Security number?"
                                >
                                    <p>
                                        Yes, if you will be the Qualifying party on the license you will need to be a
                                        U.S. Resident and provide the state with a valid social security number.
                                        However, if you will be listed on the company and not qualifying for the license
                                        you will NOT need to provide this information.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="How long does the process of getting licensed take?"
                                >
                                    <p>
                                        It all depends on each individual. However the timelines are usually as follows:
                                        8-12 hours of test prep, 2-5 Business days for Company Setup, 2-60 days for the
                                        Registrar to approve your license application.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem className="flow-text" header="Do you have a No Pass No Pay guarantee?">
                                    <p>
                                        No, we do not provide false/deceptive guarantees. When this "guarantee" is
                                        provided by other companies your cost of studying is not necessarily included,
                                        such as:
                                        <br />
                                        <br />
                                        <span className="faqInnerTitle">BOOKS</span> - You will not be refunded the cost
                                        of books, which can add up to hundreds of dollars.
                                        <br />
                                        <br />
                                        <span className="faqInnerTitle">TIME</span> - Most companies require you to take
                                        time off work or take time away from your day to come into the office to study.
                                        Also, in order to refund your studies you need to complete a minimum amount of
                                        hours to qualify.
                                        <br />
                                        <br />
                                        <span className="faqInnerTitle">GAS</span> -You will not be refunded the cost of
                                        travel to one of their few locations.
                                        <br />
                                        <br />
                                        We are one of the only companies that does not sell books and does not require
                                        you to come in to the office to register or study.
                                        <br />
                                        <br />
                                        <Link to="/licensed/testprep" className="companySetupLinks">
                                            Click here to get started today!
                                        </Link>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem className="flow-text" header="Are the books necessary to study?">
                                    <p>
                                        No, if you will be using our study program the books will not be recommended for
                                        purchase. Our program is based on the simplest and fastest way our clients can
                                        get to the point of passing their exams. We understand that the state exam is
                                        open book, in order to give our clients the best chance at passing we do have
                                        those books for a small check out fee for you to take with you. We also
                                        understand that each client is different, and some may want to purchase those
                                        books.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="How long do I need to study before passing the exam?"
                                >
                                    <p>
                                        With our program you will only need to focus on studies for 8-12 hours per exam.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem className="flow-text" header="Can I study from my phone?">
                                    <p>
                                        Yes, our online studies are mobile friendly, you can easily log in, study and
                                        review your practice exams from your phone or tablet.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="Do you offer classes or instructors to help with studying?"
                                >
                                    <p>
                                        Our program is made so that you will not need to attend a class, it is a
                                        straight forward course. However, if you have questions regarding your trade and
                                        need help, we do have an Instructor that can answer questions for you.
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="When purchasing a license bond, will you run the owners credit?"
                                >
                                    <p>
                                        No, we do not run credit on license bonds and they are instant issue (pick up in
                                        office same-day)
                                        <br />
                                        <br />
                                        For a list of our prices <br />
                                        <Link to="/bonded/licensebonds" className="companySetupLinks">
                                            Click here
                                        </Link>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="What is the difference between a bond and insurance?"
                                >
                                    <p>
                                        A bond is a guarantee in the form of credit. The bond is a set amount based on
                                        the requirements of the state. The surety company places that amount there on
                                        behalf of the client for a small premium, if the bond is claimed the client
                                        would then pay back the amount of the bond to the Surety company.
                                        <br />
                                        <br />
                                        For Bond prices and/or to purchase a bond please visit our
                                        <br />
                                        <Link to="/bonded/licensebonds" className="companySetupLinks">
                                            License Bonds
                                        </Link>{" "}
                                        page.
                                        <hr />
                                        Insurance is not required by the state for you to obtain a Contractor license,
                                        and will usually be a requirement of your clients. This is usually a monthly
                                        premium based on your work, sales and payroll. If there is an insurance claim,
                                        you will not need to pay back the insurance company and you would continue to
                                        only pay your monthly premium.
                                        <br />
                                        <br />
                                        For an Insurance quote please click
                                        <Link to="/insured" className="companySetupLinks">
                                            {" "}
                                            here
                                        </Link>
                                    </p>
                                </CollapsibleItem>
                                <CollapsibleItem
                                    className="flow-text"
                                    header="What is the difference between a License bond and a Taxpayer bond?"
                                >
                                    <p>
                                        A license bond is required by the REGISTRAR OF CONTRACTORS. This bond is
                                        attached to your license and is required as long as you carry an active license.
                                        This will cover complaints or judgements against your license. A taxpayer bond
                                        is required by the DEPARTMENT OF REVENUE. This is the initial 2 year
                                        probationary period when obtaining your state transaction privilege tax number.
                                        After 2 years, you may no longer be required to carry it.
                                        <br />
                                        <br />
                                        For bond prices and information please click
                                        <Link to="/bonded" className="companySetupLinks">
                                            {" "}
                                            here
                                        </Link>
                                    </p>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Faq;
