import React, { Component } from "react";
import { Link } from "react-router-dom";

class Store extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Store</h1>
                    <div className="outerContStore">
                        <Link to="/store/studycourses">
                            <div className="storeItemCont">
                                <div className="storeItem">
                                    <p> STUDY COURSES </p>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link to="/store/bonds">
                            <div className="storeItemCont">
                                <div className="storeItem">
                                    <p> BONDS </p>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link to="/licensed/companysetup">
                            <div className="storeItemCont">
                                <div className="storeItem">
                                    <p> COMPANY SETUP </p>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link to="/insured">
                            <div className="storeItemCont">
                                <div className="storeItem">
                                    <p> INSURANCE </p>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link to="/store/licensingquote">
                            <div className="storeItemCont">
                                <div className="storeItem">
                                    <p> LICENSING QUOTE </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Store;
