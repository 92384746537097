import React from "react";
import { Row, Col } from "react-materialize";

const SiteLock = () => {
    return (
        <Row>
            <Col s={12}>
                <a
                    className="siteLock row"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.sitelock.com/verify.php?site=getlicensedbondedinsured.com"
                >
                    <img
                        className="img-responsive"
                        alt="SiteLock"
                        title="SiteLock"
                        src="//shield.sitelock.com/shield/getlicensedbondedinsured.com"
                    />
                </a>
            </Col>
        </Row>
    );
};

export default SiteLock;
