import React, { Component } from "react";
import { Link } from "react-router-dom";

class TestPrep extends Component {
    render() {
        return (
            <div class="outerCont">
                <div className="main">
                    <h1 className="title">Study Courses</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            We make studying for your test easy, fast and for the best price in town.
                            GetLicensedBondedInsured.com by American Contractor Licensing Services, Inc. has been
                            licensing contractors in Arizona for over 20 years and our success speaks for itself. What
                            that means for our clients is that you are getting the most up-to-date information in a
                            format that is easy to understand and provides high pass rates.
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            At American Contractor Licensing Services, Inc. we provide all of the books necessary for
                            you to use in our office and to rent for use on the state exam! No other company will do
                            this for you. They will recommend you purchase the books which they just so happen to sell
                            in their office.
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            To fit the needs of our clients we have taken our highly recommended, successful study
                            course and created an online environment that does not have the restriction of traveling to
                            an office, arranging schedules for business hours, or missing work opportunities. Our
                            clients get the same great service and results without missing dinner or work ever again.
                        </p>
                    </div>
                    <br />
                    <br />
                    <div className="priceBoxCont">
                        <div className="priceBox">
                            <p>ONLINE OR IN OFFICE STUDY COURSE</p>
                            <p className="dollarAmount">$275</p>
                            <p>$275 Trade</p>
                        </div>
                    </div>
                    <div className="priceBoxCont">
                        <div className="priceBox">
                            <p>IN OFFICE</p>
                            <p className="dollarAmount">$125</p>
                            <p>$125 SRE (Statutes and Rules Exam) *Add binder for $45</p>
                        </div>
                    </div>
                    <p className="licenseStep">1st in Arizona to offer online services</p>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            Save GAS. Save TIME. Save MONEY! Never miss work or dinner again. Study ANYTIME and
                            ANYWHERE!
                            <br />
                            30 days unlimited studies. Extensions available.
                        </p>
                    </div>
                    <br />
                    <br />
                    <br />
                    <h4>
                        <Link to="/store/studycourses" className="companySetupLinks">
                            SIGN UP BY CLICKING HERE!
                        </Link>
                    </h4>
                    <h5>
                        GetLicensedBondedInsured.com
                        <br />
                        (602) 242-2222
                    </h5>
                </div>
            </div>
        );
    }
}

export default TestPrep;
