import React, { Component } from "react";
import { Modal } from "react-materialize";

class CompanySetup extends Component {
    render() {
        return (
            <div class="outerCont">
                <div className="main">
                    <h1 className="title">Setting up a company in Arizona</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            Arizona DOES NOT LICENSE A PERSON! Arizona provides a LICENSE to the BUSINESS! The state
                            requires the applicant to PRE-SELECT how the person intends to set up the company BEFORE the
                            license is issued. Which company operating entity is best for you and your family in this
                            business endeavor? If you change the business entity after the license is issued, you must
                            re-apply and repeat the licensing process. This is an important concept to understand. The
                            law does not permit, under any circumstance, allow one person to "use" someone else's
                            license. The unlicensed person can be charged with contracting without a license and the
                            licensed company can be charged with aiding and abetting.
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            At GetLicensedBondedInsured.com, we specialize in the paperwork necessary to setup the
                            company that will best suit your business needs. Get more information below on the
                            differences in company structures. If you have more questions, we recommend speaking with
                            your accountant or Lawyer.
                        </p>
                    </div>
                    <div>
                        <h5>
                            <Modal
                                header="Corporations"
                                trigger={
                                    <a href="#">
                                        <span className="companySetupLinks">Corporations</span>
                                    </a>
                                }
                            >
                                <p className="flow-text">
                                    The corporation seems to be favored by most long-term successful businesses. The
                                    Corporation provides the shareholders protection from total loss (unlike in
                                    partnerships and sole proprietorships) through a legal shield referred to as the
                                    Corporate Veil. By design, the shareholders of the corporation are exposed to the
                                    limit of their investment (stock). Arizona allows one person corporations, Therein,
                                    the same protection from corporate structure is awarded the one person corporation
                                    as is provided the large multinational corporations. Corporations are all filed as C
                                    corporations. Many small corporations elect Sub Chapter S status to eliminate the
                                    corporate income tax (some refer to this as double taxation). Your attorney and/or
                                    accountant should be able to answer additional questions you may have.
                                </p>
                            </Modal>
                        </h5>
                        <br />
                        <h5>
                            <Modal
                                header="Limited Liability Company (LLC)"
                                trigger={
                                    <a href="#">
                                        <span className="companySetupLinks">Limited Liability Companies (LLCs)</span>
                                    </a>
                                }
                            >
                                <p className="flow-text">
                                    The Limited Liability Company is best described as a Partnership given some of the
                                    business benefits of the Corporation. This entity seems to be preferred by lawyers
                                    and accountants. However, there seems to be an inherent conflict in licensing a
                                    business when set up as a construction business. The State of California has decided
                                    to no longer license Contractors if they decide to choose an LLC as the business
                                    entity unless they are a very large company. That may provide a clue to future
                                    licensing issues in Arizona. The Registrar of Contractors requires complete new
                                    licensing if the business entity changes. The U.S. Tax Guide uses the word
                                    partnership in every paragraph of the LLC section adding to the confusion. Be
                                    informed! If you need legal or accounting advice, GET IT!
                                </p>
                            </Modal>
                        </h5>
                        <br />
                        <h5>
                            <Modal
                                trigger={
                                    <a href="#">
                                        <span className="companySetupLinks">Sole Proprietorship / Partnerships</span>
                                    </a>
                                }
                            >
                                <h4>Sole Proprietorship</h4>
                                <p className="flow-text">
                                    A Sole Proprietorship is described as a business entity which has no separation of
                                    business and personal assets/liabilities. The "Sole" refers to having but one owner
                                    who takes on the responsibilities (good AND bad) of the company.
                                </p>
                                <p className="flow-text">
                                    Advantages: More simple to form, simpler taxes, less government regulations, less
                                    complex company structure.
                                </p>
                                <p className="flow-text">
                                    Disadvantages: Complete personal liability and loss potential, harder to obtain
                                    financing, no perpetuity - cannot sell or transfer ownership, the business dies when
                                    the owner leaves, retires or dies and so does the Arizona Contractors License.
                                </p>
                                <h4>Partnership</h4>
                                <p className="flow-text">
                                    By definition, a Partnership is a business entity of more than one owner. The owners
                                    of a partnership share all profits and losses of the company equally. There is no
                                    separation of business and personal assets/liabilities. Roughly, it's a business
                                    marriage of two or more sole proprietorships by Partnership Agreement.
                                </p>
                                <p className="flow-text">
                                    Advantages and Disadvantages are similar to the Sole Proprietor. Change in ONE or
                                    MORE of the partners results in the extinction of the Partnership and loss of the
                                    Arizona Contractors License. Both Limited and General Partnership structures carry
                                    business losses and risk to the partners.
                                </p>
                            </Modal>
                        </h5>
                        <br />
                        <iframe
                        id="JotFormIFrame-212756537991165"
                        onload="window.parent.scrollTo(0,0)"
                        allowtransparency="false"
                        allowfullscreen="true"
                        src="https://form.jotform.com/212756537991165"
                        frameborder="0"
                        style={{ width: "100%", height: "600px" }}
                        scrolling="no"
                    />
                        <h5>
                            GetLicensedBondedInsured.com
                            <br />
                            (602) 242-2222
                        </h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanySetup;
