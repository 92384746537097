import React, { Component } from "react";
import "./App.css";
import { Navbar, NavItem, Dropdown, Modal } from "react-materialize";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import Soc from "./Components/Social";
import Foot from "./Components/Footer";
import SiteLock from "./Components/SiteLock";

import HomePage from "./Components/Home/HomeMain";
import About from "./Components/About/AboutMain";
import Contact from "./Components/Contact/ContactMain";
import Store from "./Components/Store/StoreMain";
import CompanySetup from "./Components/Licensed/CompanySetup";
import Licensed from "./Components/Licensed/LicensedMain";
import Bonded from "./Components/Bonded/BondedMain";
import Insured from "./Components/Insured/InsuredMain";
import Payment from "./Components/Payment/PaymentMain";
import Study from "./Components/Study/StudyMain";
import IndLinks from "./Components/IndLinks/IndLinksMain";
import LicenseBonds from "./Components/Bonded/LicenseBonds";
import TaxBonds from "./Components/Bonded/TaxBonds";
import OtherBonds from "./Components/Bonded/OtherBonds";
import LicenseClassifications from "./Components/Licensed/LicenseClassifications";
import TestPrep from "./Components/Licensed/TestPrep";
import Bonds from "./Components/Store/Bonds";
import StudyCourses from "./Components/Store/StudyCourses";
import LicensingQuote from "./Components/Store/LicensingQuote";
import TermsAndConditions from "./Components/TermsAndConditions";
import Faq from "./Components/FAQ/Faq";
import Logo from "./Components/Logo";
import Warning from "./Components/Warning/Warning";

class App extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <div className="App">
                        <div className="navBar-fixed">
                            <Navbar fixed options={{ closeOnClick: true }} className="navBar navBar-fixed">
                                <Link className="itemBackground" to="/">
                                    <NavItem>
                                        <span className="navText">Home</span>
                                    </NavItem>
                                </Link>
                                <Link className="itemBackground" to="/about">
                                    <NavItem>
                                        <span className="navText">About Us</span>
                                    </NavItem>
                                </Link>
                                <Dropdown
                                    trigger={
                                        <NavItem className="itemBackground" data-belowOrigin="true" data-hover="true">
                                            <Link to="/licensed">
                                                <span className="navText">Licensed</span>
                                            </Link>
                                        </NavItem>
                                    }
                                >
                                    <Link className="pullDownCont" to="/licensed/companysetup">
                                        <NavItem>
                                            <span className="navDropDownText">Company Setup</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/licensed/licenseclassifications">
                                        <NavItem>
                                            <span className="navDropDownText">License Classifications</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/licensed/testprep">
                                        <NavItem>
                                            <span className="navDropDownText ">Test Prep</span>
                                        </NavItem>
                                    </Link>
                                </Dropdown>
                                <Dropdown
                                    trigger={
                                        <NavItem className="itemBackground" data-belowOrigin="true" data-hover="true">
                                            <Link className="pullDownCont" to="/bonded">
                                                <span className="navText">Bonded</span>
                                            </Link>
                                        </NavItem>
                                    }
                                >
                                    <Link className="pullDownCont" to="/bonded/licensebonds">
                                        <NavItem>
                                            <span className="navDropDownText">License Bonds</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/bonded/taxbonds">
                                        <NavItem>
                                            <span className="navDropDownText">Tax Bonds</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/bonded/otherbonds">
                                        <NavItem>
                                            <span className="navDropDownText">Other Bonds</span>
                                        </NavItem>
                                    </Link>
                                </Dropdown>
                                <Link className="pullDownCont itemBackground" to="/insured">
                                    <NavItem data-belowOrigin="true" data-hover="true">
                                        <span className="navText">Insured</span>
                                    </NavItem>
                                </Link>
                                <Dropdown
                                    trigger={
                                        <NavItem className="itemBackground" data-belowOrigin="true" data-hover="true">
                                            <Link className="pullDownCont" to="/store">
                                                <span className="navText">Store</span>
                                            </Link>
                                        </NavItem>
                                    }
                                >
                                    <Link className="pullDownCont" to="/store/studycourses">
                                        <NavItem>
                                            <span className="navDropDownText">Study Courses</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/store/bonds">
                                        <NavItem>
                                            <span className="navDropDownText">Bonds</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/licensed/companysetup">
                                        <NavItem>
                                            <span className="navDropDownText">Company Setup</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/insured">
                                        <NavItem>
                                            <span className="navDropDownText">Insurance</span>
                                        </NavItem>
                                    </Link>
                                    <NavItem divider />
                                    <Link className="pullDownCont" to="/store/licensingquote">
                                        <NavItem>
                                            <span className="navDropDownText">License Quote</span>
                                        </NavItem>
                                    </Link>
                                </Dropdown>
                                <Link className="itemBackground" to="/payment">
                                    <NavItem>
                                        <span className="navText">Make A Payment</span>
                                    </NavItem>
                                </Link>
                                <Link className="pullDownCont itemBackground" to="/study">
                                    <NavItem>
                                        <span className="navText">Study</span>
                                    </NavItem>
                                </Link>
                                <Link className="itemBackground" to="/contact">
                                    <NavItem>
                                        <span className="navText">Contact</span>
                                    </NavItem>
                                </Link>
                                <Link className="itemBackground" to="/industryLinks">
                                    <NavItem>
                                        <span className="navText">Industry Links</span>
                                    </NavItem>
                                </Link>
                                <Link className="itemBackground" to="/faq">
                                    <NavItem>
                                        <span className="navText">FAQ</span>
                                    </NavItem>
                                </Link>
                            </Navbar>
                        </div>

                        <div className="outerCont">
                            <Logo />
                            {/* <Warning /> */}
                            <Route exact path="/" component={HomePage} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/store" component={Store} />
                            <Route exact path="/store/bonds" component={Bonds} />
                            <Route exact path="/store/studycourses" component={StudyCourses} />
                            <Route exact path="/store/licensingquote" component={LicensingQuote} />
                            <Route exact path="/store/companysetup" component={CompanySetup} />
                            <Route exact path="/licensed" component={Licensed} />
                            <Route exact path="/licensed/companysetup" component={CompanySetup} />
                            <Route exact path="/licensed/licenseclassifications" component={LicenseClassifications} />
                            <Route exact path="/licensed/testprep" component={TestPrep} />
                            <Route exact path="/bonded" component={Bonded} />
                            <Route exact path="/bonded/licensebonds" component={LicenseBonds} />
                            <Route exact path="/bonded/taxbonds" component={TaxBonds} />
                            <Route exact path="/bonded/otherbonds" component={OtherBonds} />
                            <Route exact path="/insured" component={Insured} />
                            <Route exact path="/payment" component={Payment} />
                            <Route exact path="/study" component={Study} />
                            <Route exact path="/industryLinks" component={IndLinks} />
                            <Route exact path="/termsandconditions" component={TermsAndConditions} />

                            <Route exact path="/faq" component={Faq} />
                            <Soc />
                            <SiteLock />
                            <Foot />
                        </div>
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;
