import React, { Component } from "react";
import { Modal } from "react-materialize";

class StudyCourses extends Component {
    render() {
        return (
            <div class="outerCont">
                <div className="main">
                    <h1 className="title">Study Courses</h1>
                    <Modal
                        header="Statutes and Rules Exam"
                        trigger={
                            <a className="companySetupLinks" style={{ cursor: "pointer" }}>
                                <h5>Statutes and Rules Exam</h5>
                            </a>
                        }
                    >
                        <p className="flow-text">
                            This is the course and exam required by the Arizona Registrar of Contractors. This course
                            will review the Statutes, Rules and Workmanship standards of the State of Arizona and will
                            end with an exam. We will provide you with the printable material to guide you through the
                            course. It will take between 2-4 hours to complete.
                            <br />
                            <br />
                            In-Office availability.
                        </p>
                    </Modal>

                    <br />

                    <Modal
                        header="Trade Study Course"
                        trigger={
                            <a className="companySetupLinks" style={{ cursor: "pointer" }}>
                                <h5>Trade Study Course</h5>
                            </a>
                        }
                    >
                        <p className="flow-text">
                            The Contractor Trade Study Course gives you what you need to pass the exam. You will be
                            allowed to review the correct answers to the questions you missed, get your percentage, and
                            how much time you took to complete the pretest and code references.
                            <br />
                            <br />
                            Online and in office courses available.
                            <br />
                            <br />
                            Book rentals available in office 2 days before the scheduled exam.
                            <br />
                            <br />
                            Average study time to pass: 8 - 12 hours.
                        </p>
                    </Modal>
                    <iframe
                        id="JotFormIFrame-80297356808164"
                        onload="window.parent.scrollTo(0,0)"
                        allowtransparency="false"
                        allowfullscreen="true"
                        src="https://form.jotform.com/80297356808164"
                        frameborder="0"
                        style={{ width: "100%", height: "600px" }}
                        scrolling="no"
                    />
                </div>
            </div>
        );
    }
}

export default StudyCourses;
