import React from "react";

class AppointmentOnly extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showWarning: true };
    }

    handleWarning = e => {
        e.preventDefault();
        this.setState({ showWarning: false });
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div
                        className="col s12"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            // marginTop: 15,
                            marginBottom: 0,
                        }}
                    >
                        <h4>
                            CURBSIDE SERVICE AVAILABLE!
                            <br />
                            <br />
                            PLEASE GIVE US A CALL TO <br />
                            SET A TIME. <br />
                            (602) 242-2222
                        </h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppointmentOnly;
