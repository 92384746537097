import React from "react";
import { Row, Col } from "react-materialize";

const Foot = () => {
    return (
        <Row className="footerMain">
            <div className="footerTextCont">
                <Col s={4}>
                    <p className="footerText">Phone:(602) 242-2222</p>
                    <p className="footerText">Fax:(602) 433-1219</p>
                </Col>
                <Col s={4}>
                    <p className="footerText">Copyright © 2013 – 2019 American Contractor Licensing Services, Inc.</p>
                </Col>
                <Col s={4}>
                    <p className="footerText">6717 N.Black Canyon Hwy Phx, AZ 85015</p>
                </Col>
            </div>
        </Row>
    );
};

export default Foot;
