import React, { Component } from "react";

class Study extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Study Login</h1>
                    <iframe
                        className="examDoc"
                        src="https://my.examprofessor.com/getlicensedbondedinsured"
                        style={{ width: "100%", height: "600px" }}
                        title="Study Course Login"
                    />
                </div>
            </div>
        );
    }
}

export default Study;
