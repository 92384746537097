import React, { Component } from "react";

class IndLinks extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <div className="indLinksCont">
                        <h1 className="title">Industry Links</h1>
                        <div className="indLinksInnerCont">
                            <p className="flow-text">Following is a set of links you may find helpful:</p>
                            <ul>
                                <br />
                                <li className="flow-text">
                                    ROC News Updates
                                    <br />
                                    <a
                                        className="flow-text"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="http://www.azroc.gov/"
                                    >
                                        http://www.azroc.gov/
                                    </a>
                                </li>
                                <br />
                                <li className="flow-text">
                                    Arizona Corporation Commission Updates
                                    <br />
                                    <a
                                        className="flow-text"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="http://www.azcc.gov/"
                                    >
                                        http://www.azcc.gov/
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IndLinks;
