import React, { Component } from "react";
import { Row, Col } from "react-materialize";
import { people } from "./peopleDetails";

class About extends Component {
    renderAllProfiles = () => {
        return people.map(person => {
            return (
                <Row>
                    <Col s={12} m={12} l={12}>
                        <img className={person.classRef} alt={person.name} src={person.pic.webP} />
                        <div className="aboutTextBlock">
                            <div className="flow-text">
                                <p>
                                    <span className="name">{person.name}</span>
                                    <br />
                                    <span className="jobTitle">{person.jobTitle}</span>
                                    <br />
                                    {person.description}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        });
    };
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Getting To Know Us</h1>
                    {this.renderAllProfiles()}
                </div>
            </div>
        );
    }
}

export default About;
