import React, { Component } from "react";

class TermsAndConditions extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <div>
                        <h4 className="title">Terms and Conditions</h4>
                        <div className="pageTextMarg">
                            <p className="flow-text">
                                PRIVACY POLICY
                                <br />
                                www.GetLicensedBondedInsured.com solely owns all of the information that is collected
                                within this site. Information is collected from users at various points throughout the
                                web site. No information will be sold to or distributed out-side of the internal
                                workings of this web site or American Contractor Insurance and Bonds, Inc. or American
                                Contractor Licensing Services, Inc. for personal or commercial use except services
                                requested such as order fulfillment, renewals, additional services or questions which
                                must be answered by someone outside American Contractor Insurance and Bonds, Inc or
                                American Contractor Licensing Services, Inc. You may receive emails from
                                GetLicensedBondedInsured.com by American Contractor Licensing Services, Inc. and
                                American Contractor Insurance and Bonds, Inc. These emails can be anything from
                                Newsletters, Industry News, Additional Services, Delivery of Products, etc. If you wish
                                to opt out of these emails please send an email to Deisy@getlicensedbondedinsured.com
                                with a statement to remove your email from our lists.
                                <br />
                                <br />
                                TERMS AND CONDITIONS
                                <br />
                                The following Terms and Conditions apply to the use of this Web site as well as all
                                transactions conducted through the site.
                                <br />
                                <br />
                                Legal Notice
                                <br />
                                All notices from GetLicensedBondedInsured.com to You may be posted on our Web site and
                                will be deemed delivered within thirty (30) days after posting. Notices from You to
                                GetLicensedBondedInsured.com shall be made either by e-mail, sent to the address we
                                provide on our Web site. Delivery shall be deemed to have been made by You to
                                GetLicensedBondedInsured.com five (5) days after the date sent.
                                <br />
                                <br />
                                Copyright
                                <br />
                                All content appearing on this Web site is the property of: GetLicensedBondedInsured.com
                                Copyright © 2013-2017 GetLicensedBondedInsured.com. All rights reserved. As a user, you
                                are authorized only to view documents on this Web site. No printing or distribution of
                                material is permitted.
                                <br />
                                <br />
                                Trademarks
                                <br />
                                All brand, product, service, and process names appearing on this Web site are trademarks
                                of their respective holders. Reference to or use of a product, service, or process does
                                not imply recommendation, approval, affiliation, or sponsorship of that product,
                                service, or process by GetLicensedBondedInsured.com. Nothing contained herein shall be
                                construed as conferring by implication, estoppel, or otherwise any license or right
                                under any patent, copyright, trademark, or other intellectual property right of
                                GetLicensedBondedInsured.com or any third party, except as expressly granted herein.
                                <br />
                                <br />
                                Use Of Site
                                <br />
                                This site may contain other proprietary notices and copyright information, the terms of
                                which must be observed and followed. Information on this site may contain technical
                                inaccuracies or typographical errors. Information, including product pricing and
                                availability, may be changed or updated without notice. GetLicensedBondedInsured.com and
                                its subsidiaries reserve the right to refuse service, terminate accounts, and/or cancel
                                orders in its discretion, including, without limitation, if GetLicensedBondedInsured.com
                                believes that customer conduct violates applicable law or is harmful to the interests of
                                GetLicensedBondedInsured.com and its subsidiaries.
                                <br />
                                <br />
                                Privacy Policy
                                <br />
                                GetLicensedBondedInsured.com use of personal information that you may submit to
                                GetLicensedBondedInsured.com through this Web site is governed by the
                                GetLicensedBondedInsured.com Privacy Policy.
                                <br />
                                <br />
                                Consumer Agreement
                                <br />
                                GetLicensedBondedInsured.com is not responsible for scheduling or making sure the
                                correct test is scheduled with PSI Exams. As a courtesy we may provide information on
                                how to contact the testing company and information on the course purchased but this does
                                not in any way constitute liability for incorrect scheduling or license determination.
                                <br />
                                <br />
                                Warranties
                                <br />
                                The information and content on this server is provided "as is" with no warranty of any
                                kind, either express or implied, including but not limited to the implied warranties of
                                merchantability, fitness for a particular purpose, and non-infringement. Any warranty
                                that is provided in connection with any of the products and services described on this
                                Web site is provided by the advertiser or manufacturer only, and not by
                                GetLicensedBondedInsured.com. The references and descriptions of products or services
                                within the Web site materials are provided "as is" without any warranty of any kind,
                                either express or implied. GetLicensedBondedInsured.com is not liable for any damages,
                                including any consequential damages, of any kind that may result to the user from the
                                use of the materials on this Web site or of any of the products or services described
                                hereon. The descriptions of, and references to, products, services and companies on this
                                Web site are the sole responsibility of the companies providing the information
                                ("advertisers"), and not GetLicensedBondedInsured.com. The inclusion of material on this
                                server does not imply any endorsement by GetLicensedBondedInsured.com, which makes no
                                warranty of any kind with respect to the subject matter of the server materials
                                advertised. A possibility exists that the server materials could include inaccuracies or
                                errors. Additionally, a possibility exists that unauthorized additions, deletions, and
                                alterations could be made by third parties to the server materials. Although
                                GetLicensedBondedInsured.com tries to ensure the integrity and the accurateness of the
                                server materials, it makes no guarantees about their correctness or accuracy. Before
                                relying on any representation made in any of the server materials, check with the
                                advertiser of the product or service to ensure that the information you are relying upon
                                is correct.
                                <br />
                                <br />
                                Return Policy
                                <br />
                                You may purchase merchandise from this Web site by using any one of the payment options
                                listed in the store. Return Policy: All courses are sold as is. There are no refunds or
                                transfers on services/products purchased. We are not responsible for providing books or
                                reference material necessary to pass the exams. Online Course expiration date is 30 days
                                from the date of emailed registration. In Office courses will expire 1 year from the
                                date of payment. There are no extensions on time because client didn't access the
                                material in time or did not receive the emailed registration. It is the client's
                                obligation to contact GetLicensedBondedInsured.com at (602) 242-2222 if a registration
                                is not received within 24-48 hours of payment. Bond premiums are earned upon
                                issuance/renewal. There is no refund for unused time left on bonds. License bonds are
                                active for 30 days and must be delivered to the Arizona Registrar of Contractors within
                                that period of time. Tax bods are active for 30 days and must be delivered to the
                                Arizona Department of Revenue within that period of time. No changes can be made to
                                bonds after 60 days. New bonds may need to be purchased if outside these time periods.
                                <br />
                                <br />
                                Miscellaneous
                                <br />
                                VOID WHERE PROHIBITED: Although the information on this Web site is accessible
                                worldwide, not all products or services discussed in this Web site are available to all
                                persons or in all geographic locations or jurisdictions. GetLicensedBondedInsured.com
                                and the advertisers each reserve the right to limit the provision of their products or
                                services to any person, geographic area, or jurisdiction they so desire and to limit the
                                quantities of any products or services that they provide. Any offer for any product or
                                service made in the materials on this Web site is void where prohibited.
                                <br />
                                <br />
                                GOVERNING LAW: In the event of litigation both parties agree that the Law of the State
                                of business registration of GetLicensedBondedInsured.com shall apply and both parties
                                shall consent to the jurisdiction of said State's courts, or in the event of diversity
                                of citizenship, the United States District Court for the (District). Both parties
                                expressly waive a trial by jury.
                                <br />
                                <br />
                                MISCELLANEOUS: The Terms and Conditions constitute the entire agreement between you and
                                GetLicensedBondedInsured.com with respect to this Web site. The Terms and Conditions
                                supersede all prior or contemporaneous communications and proposals, whether electronic,
                                oral or written between you and GetLicensedBondedInsured.com with respect to this Web
                                site. No modification of the Terms and Conditions shall be effective unless it is
                                authorized by GetLicensedBondedInsured.com. If any provision of the Terms and Conditions
                                is found to be contrary to law, then such provision(s) shall be constructed in a manner
                                to closely reflect, as much as possible, the intentions of the parties, with the other
                                provisions remaining in full force and effect.*
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsAndConditions;
