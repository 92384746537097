import React, { Component } from "react";
import { Table } from "react-materialize";
import { Link } from "react-router-dom";

class LicenseBonds extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Contractor License Bonds</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            Our Contractor License Bonds are "INSTANT ISSUE". What does this mean for you? It means that
                            <br />
                            1: You get your bond the same day you pay for it
                            <br />
                            2: There are NO qualifications or underwriting requirements
                            <br />
                            3: NO CREDIT CHECK means that your price is not dependent on your credit history and getting
                            a bond does not harm your credit score by having it checked.
                            <br />
                            <h4 className="weWillNotRun">
                                WE WILL NOT RUN YOUR CREDIT. OUR NO CREDIT CHECK PRICES BEAT THE COMPETITORS PERFECT
                                CREDIT SCORE PRICES!!
                            </h4>
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            The Arizona Registrar of Contractors requires that every contractor obtain a license bond
                            based on yearly work sales volume. For work over a million dollars per year, call (602)
                            242-2222 for prices.
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            To obtain a new bond, please click{" "}
                            <Link to="/store/bonds" className="companySetupLinks">
                                here
                            </Link>
                            . The bond will be issued and mailed within 24 hours. If you need the bond immediately,
                            please visit our office at 6717 N. Black Canyon Hwy, Phoenix, AZ 85015 and you can walk out
                            with the bond.**
                        </p>
                    </div>
                    <br />
                    <h5 className="collapseTitle">Commercial General License Bonds</h5>
                    <Table className="centered tables">
                        <thead>
                            <tr>
                                <th data-field="id">Sales Volume</th>
                                <th data-field="name">Bond Amount</th>
                                <th data-field="price">
                                    No Credit Check <br /> 2 Year Premium
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Less than $150,000</td>
                                <td>$5,000.00</td>
                                <td>$88</td>
                            </tr>
                            <tr>
                                <td>$150,000 to $499,999</td>
                                <td>$15,000</td>
                                <td>$124</td>
                            </tr>
                            <tr>
                                <td>$500,000 to $999,999</td>
                                <td>$25,000</td>
                                <td>$208</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <h5 className="collapseTitle">Commercial Specialty License Bonds</h5>
                    <Table className="centered tables">
                        <thead>
                            <tr>
                                <th data-field="id">Sales Volume</th>
                                <th data-field="name">Bond Amount</th>
                                <th data-field="price">
                                    No Credit Check <br /> 2 Year Premium
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Less than $150,000</td>
                                <td>$5,000.00</td>
                                <td>$88</td>
                            </tr>
                            <tr>
                                <td>$150,000 to $499,999</td>
                                <td>$7,000</td>
                                <td>$88</td>
                            </tr>
                            <tr>
                                <td>$500,000 to $999,999</td>
                                <td>$17,500</td>
                                <td>$145</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <h5 className="collapseTitle">Residential General License Bonds</h5>
                    <Table className="centered tables">
                        <thead>
                            <tr>
                                <th data-field="id">Sales Volume</th>
                                <th data-field="name">Bond Amount</th>
                                <th data-field="price">
                                    No Credit Check <br /> 2 Year Premium
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Less than $750,000</td>
                                <td>$9,000</td>
                                <td>$450</td>
                            </tr>
                            <tr>
                                <td>$750,000 or Greater</td>
                                <td>$15,000</td>
                                <td>$749</td>
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    <h5 className="collapseTitle">Residential Specialty License Bonds</h5>
                    <Table className="centered tables">
                        <thead>
                            <tr>
                                <th data-field="id">Sales Volume</th>
                                <th data-field="name">Bond Amount</th>
                                <th data-field="price">
                                    No Credit Check <br /> 2 Year Premium
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Less than $375,000</td>
                                <td>$4,250</td>
                                <td>$212</td>
                            </tr>
                            <tr>
                                <td>$375,000 or Greater</td>
                                <td>$7,500</td>
                                <td>$375</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default LicenseBonds;
