import React, { Component } from "react";

class OtherBonds extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Other Bonds</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            There are many bonds available and serve different purposes. Below is a short list of some
                            of the other bonds we write on a regular basis.
                        </p>
                    </div>
                    <ul className="flow-text">
                        <li>Contract Bonds</li>
                        <li>Bid/Performance Bonds</li>
                        <li>Notary Bonds</li>
                        <li>Lost Title/DMV Bonds</li>
                        <li>Dealership Bonds</li>
                        <li>Business Service Bonds</li>
                        <li>Employee Dishonesty Bonds</li>
                        <li>SRP Utility Bonds</li>
                        <li>APS Utility Bonds</li>
                        <li>Brewers Bonds</li>
                    </ul>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            If you do not see the bond you need or for more information, please call us so we can assist
                            you.
                            <br />
                            (602) 242-2222.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default OtherBonds;
