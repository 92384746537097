import React, { Component } from "react";
import { Modal } from "react-materialize";

class Insured extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">Get Insured</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            At GetLicensedBondedInsured.com we provide insurance for contractors. Most insurance
                            companies do not want to take on the risk of the construction industry. Because of this we
                            have sought out the best companies, providing the best prices for contracting businesses. We
                            can quote and bind general liability policies the same day.
                        </p>
                    </div>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            We can help with ANY of your insurance needs. General Liability Insurance is NOT REQUIRED to
                            obtain an Arizona contractors license. However, companies and customers you do work for
                            typically expect you to carry General Liability Insurance and may even withhold payment
                            until they receive proof of insurance. Therein, insurance becomes required by most
                            businessmen in the construction industry. We assist you with Workers Compensation and Auto.
                            We can also assist with tool and equipment insurance.
                        </p>
                    </div>
                    <div>
                        <h5>
                            <Modal
                                header="General Liability"
                                trigger={
                                    <a className="companySetupLinks" style={{ cursor: "pointer" }}>
                                        General Liability
                                    </a>
                                }
                            >
                                <p className="flow-text">
                                    General Liability Insurance is not a requirement by the State of Arizona but is
                                    almost ALWAYS required by the people you do work for. Other contractors, project
                                    owners, and customers will ask for proof of insurance. In some instances you will
                                    begin a project without showing proof of insurance and at completion be required to
                                    provide proof before collecting payment. This leaves many contractors scrambling to
                                    find insurance quickly.
                                </p>
                            </Modal>
                        </h5>

                        <h5>
                            <Modal
                                header="Workers Comp"
                                trigger={
                                    <a className="companySetupLinks" style={{ cursor: "pointer" }}>
                                        Workers Comp
                                    </a>
                                }
                            >
                                <p className="flow-text">
                                    Worker's Compensation Insurance is required as soon as you hire ONE employee. It
                                    does not matter if that employee is full time, part time, or seasonal. The state
                                    requires you to carry worker's comp on them. If you 1099 your "employees" they are
                                    not technically your employees and they cannot "use" your license or be covered by
                                    your license. Also, if they are not licensed with the Registrar of Contractors, you
                                    may be cited for aiding and abetting an unlicensed contractor.
                                    <br />
                                    <br />
                                    Worker's compensation insurance can be more timely and difficult to obtain so it is
                                    a good idea to get it in place right away. To get a quote with us, please contact us
                                    at (602) 242-2222 or at deisy@getlicensedbondedinsured.com. We will shop around for
                                    you and if we are unable to get you covered we will refer you to a great agent.
                                </p>
                            </Modal>
                        </h5>

                        <h5>
                            <Modal
                                trigger={
                                    <a className="companySetupLinks" style={{ cursor: "pointer" }}>
                                        Auto
                                    </a>
                                }
                            >
                                <h4>Auto</h4>
                                <p className="flow-text">
                                    At GetLicensedBondedInsured.com we can write the auto insurance for your personal or
                                    commercial vehicles. We can also insure classics, collectibles, hot rods,
                                    motorcycles, boats, and RVs. Quick, easy, no obligation quotes. Give us a call today
                                    and we will be happy to help you!
                                </p>
                            </Modal>
                        </h5>
                        <div className="pageTextMarg">
                            <p className="flow-text">
                                At GetLicensedBondedInsured.com we work with companies who WANT to work with
                                contractors. This is beneficial to our customers because they get the best policies at
                                the best price with the best coverage. Other insurance companies will try to disguise a
                                sub-par policy by showing you a policy with the same general limits at a smaller price
                                but they usually will not cover the majority of the work being performed. All of our
                                policies come with a "Blanket Additional Insured" endorsement. In construction, almost
                                every person you work with or for will ask for you to name them as additional insured on
                                your insurance policy. On average these certificates cost $150-$275 PER CERTIFICATE. Our
                                policies include unlimited certificates. This is a HUGE SAVINGS!
                            </p>
                        </div>
                        <p className="flow-text">Complete our online form below for a free, no obligation quote.</p>
                        <br />
                        <div>
                            <iframe
                                id="JotFormIFrame-80315153908151"
                                onload="window.parent.scrollTo(0,0)"
                                allowtransparency="false"
                                allowfullscreen="true"
                                src="https://form.jotform.com/80315153908151"
                                frameborder="0"
                                style={{ width: "100%", height: "600px" }}
                                scrolling="no"
                            />
                        </div>
                        <br />
                        <br />
                        <div className="pageTextMarg">
                            <p className="flow-text">
                                On top of huge savings and great coverage, we can get you proof of insurance within a
                                few business hours!
                            </p>
                        </div>
                        <br />
                        <h5>
                            Call us for a free quote today!
                            <br />
                            (602) 242-2222
                        </h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default Insured;
