import React, { Component } from "react";
import { Collapsible, CollapsibleItem } from "react-materialize";

class LicenseClassifications extends Component {
    render() {
        return (
            <div class="outerCont">
                <div className="main">
                    <h1 className="title">License Classifications</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            Click on the license classification below for more information about the work experience and
                            exam requirements.
                        </p>
                    </div>
                    <p className="flow-text">
                        For more details on license classifications click here
                        <br />
                        <a target="_blank" rel="noopener noreferrer" href="https://roc.az.gov/license-classifications">
                            Arizona Registrar of Contractors
                        </a>
                    </p>
                    <div className="licenseClassificationsCont">
                        <div className="collapseCont">
                            <h5 className="collapseTitle">Commercial Engineering Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="A General Engineering">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem className="collapseItem" header="A-4 Drilling">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-5 Excavating, Grading and Oil Surfacing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-7 Piers and Foundations">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-9 Swimming Pools   ">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-11 Steel and Aluminum Erection     ">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-12 Sewers, Drains and Pipe Laying     ">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-14 Asphalt Paving">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-15 Seal Coating">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-16 Waterworks">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-17 Electrical and Transmission Lines">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="A-19 Swimming Pools Incl. Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">General Commercial Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="B-1 General Commercial Contractor">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-2 General Small Commercial Contractor">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">General Residential Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="B       General Residential">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-3   General Remodeling and Repair">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-4   General Residential Engineering">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-5   General Swimming Pool ">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-6   General Swimming Pool Including Solar ">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="B-10 Pre-Manufactured Spas and Hot Tubs ">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">Specialty Commercial Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="C-1 Acoustical Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-3 Awnings, Canopies, Carports and Patio Covers">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-4 Boilers, Steamfitting, Process Piping">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-6 Swimming Pool Service and Repair">
                                    <h5>
                                        Minimum Years of Experience: 1
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-7 Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-8 Floor Covering">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-9 Concrete">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-11 Electrical">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-12 Elevators">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-14 Fencing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-15 Blasting">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-16 Fire Protection Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-21 Hardscaping and Irrigation Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-24 Ornamental Metals">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-27 Lightweight Partitions">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-31 Masonry">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-34 Painting and Wall Coverings">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-36 Plastering">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-37 Plumbing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-38 Signs">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-39 Air Conditioning and Refrigeration">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-40 Insulation">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-41 Septic Tanks and Systems">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-42 Roofing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-45 Sheet Metal">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-48 Ceramic, Plastic, and Metal Tile">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-49 Refrigeration">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-53 Water Well Drilling ">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-54 Water Conditioning Equipment">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-56 Welding">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-57 Wrecking">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-58 Comfort Heating, Ventilating, Evap. Cooling">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-60 Finish Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-61 Carpentry, Remodeling, and Repairs">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-63 Appliances">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-65 Glazing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-67 Low Voltage Communication Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-70 Reinforcing Bar and Wire Mesh">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-74 Boilers, Steamfitting, Process Piping Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-77 Plumbing, Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-78 Solar Plumbing Liquid Systems Only">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="C-79 Air Conditioning and Refrigeration Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">Specialty Dual License Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="CR-1    Acoustical Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-2    Excavating, Grading and Oil Surfacing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-3    Awnings, Canopies, Carport, Patio Covers">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-4    Boilers, Steamfitting, Process Piping">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-5 (As restricted by Registrar)">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-6    Swimming Pool Service and Repair">
                                    <h5>
                                        Minimum Years of Experience: 1
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-7    Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-8    Floor Covering">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-9    Concrete">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-10  Drywall">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-11  Electrical">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-12  Elevators">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-14  Fencing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-15  Blasting">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-16  Fire Protection Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-17  Steel and Aluminum Erection">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-21 Hardscaping and Irrigation Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-24  Ornamental Metals">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-29 Machinery (As restricted by Registrar)">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-31  Masonry">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-34  Painting and Wall Covering">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-36  Plastering">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-37  Plumbing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-38  Signs">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-39  Air Conditioning, Refrigeration, Heating">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-40  Insulation">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-41  Septic Tanks and Systems">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-42  Roofing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-45  Sheet Metal">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-48  Ceramic, Plastic and Metal Tile">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-53  Water Well Drilling">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-54  Water Conditioning Equipment">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-56  Welding">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-57  Wrecking">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-58  Comfort Heating, Ventilating, Evap. Cooling">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-60  Finish Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-61  Carpentry, Remodeling and Repairs">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-63  Appliances">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-65  Glazing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-66  Seal Coating">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-67  Low Voltage Communication Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-69  Asphalt Paving">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-70 Reinforcing Bar and Wire Mesh">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-74  Boilers, Steamfitting, Process Piping Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-77  Plumbing Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-78  Solar Plumbing Liquid Systems Only">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-79  Air Conditioning and Refrigeration Incl. Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="CR-80  Sewers, Drains and Pipe Laying">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">General Dual Engineering Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="KA     Dual Engineering">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="KA-5 Dual Swimming Pool Contractor">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="KA-6 Dual Swimming Pool Contractor Incl. Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="KE- (As restricted by Registrar)">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">General Dual License Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="KB-1  Dual Building Contractor">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="KB-2  Dual Residential & Small Commercial">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="KO- (As restricted by Registrar)">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>

                        <div className="collapseCont">
                            <h5 className="collapseTitle">Specialty Residential Contracting</h5>
                            <Collapsible popout defaultActiveKey={false}>
                                <CollapsibleItem header="R-1 Acoustical Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-2      Excavating, Grading, and Oil Surfacing">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-3 Awnings, Canopies, Carports and Patio Covers">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-4 Boilers, Steamfitting, and Process Piping">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-6      Swimming Pool Service and Repair">
                                    <h5>
                                        Minimum Years of Experience: 1
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-7 Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-8 Floor Covering">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-9      Concrete">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-10 Drywall">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-11    Electrical">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-12 Elevators">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-13    Asphalt Paving">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-14 Fencing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-15 Blasting">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-16    Fire Protection Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-17    Structural Steel and Aluminum">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-21 Hardscaping and Irrigation Systems">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-22    House Moving">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-24 Ornamental Metals">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-31 Masonry">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-34 Painting and Wall Covering">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-36 Plastering">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-37    Plumbing, Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-37R Plumbing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-38 Signs">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-39    Air Conditioning & Refrigeration Including Solar">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T, S
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-39R Air Conditioning & Refrigeration">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-40 Insulation">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-41 Septic Tanks and Systems">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-42 Roofing">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-45 Sheet Metal">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-48 Ceramic, Plastic and Metal Tile">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-53    Drilling">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, W
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-54 Water Conditioning Equipment">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-56 Welding">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-57 Wrecking">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-60 Finish Carpentry">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-61 Carpentry, Remodeling and Repairs">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-62    Minor Home Improvements">
                                    <h5>
                                        Minimum Years of Experience: 0
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-63 Appliances">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-65 Glazing">
                                    <h5>
                                        Minimum Years of Experience: 3
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-67 Low Voltage Communication Systems">
                                    <h5>
                                        Minimum Years of Experience: 2
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                                <CollapsibleItem header="R-70 Reinforcing Bar and Wire Mesh">
                                    <h5>
                                        Minimum Years of Experience: 4
                                        <br />
                                        <br />
                                        Exam(s): B, T
                                    </h5>
                                </CollapsibleItem>
                            </Collapsible>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LicenseClassifications;
