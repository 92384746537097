import React from "react";

const Soc = () => {
    return (
        <div className="socialCont">
            <p>Connect with us!</p>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/getlicensedbondedinsured/">
                <i className="fa fa-facebook fa-3x" aria-hidden="true" />
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/search?q=GetLicensedBondedInsured.com+by+American+Contractor+Licensing+Services,+Inc.+Phoenix,+AZ+85015&ludocid=10821999833793098235&cad=h"
            >
                <i className="fa fa-google fa-3x" aria-hidden="true" />
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCJNi5Q7SHUH4GIOaPqc3mNA"
            >
                <i className="fa fa-youtube fa-3x" aria-hidden="true" />
            </a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.yelp.com/biz/american-contractor-licensing-services-phoenix-2?osq=getlicensedbondedinsured.com"
            >
                <i className="fa fa-yelp fa-3x" aria-hidden="true" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/contractor_inc">
                <i className="fa fa-twitter fa-3x" aria-hidden="true" />
            </a>
        </div>
    );
};

export default Soc;
