import React, { Component } from "react";

class LicensingQuote extends Component {
    render() {
        return (
            <div class="outerCont">
                <div className="main">
                    <h1 className="title">Licensing Quote</h1>
                    <iframe
                        id="JotFormIFrame-80315403808148"
                        onload="window.parent.scrollTo(0,0)"
                        allowtransparency="false"
                        allowfullscreen="true"
                        src="https://form.jotform.com/80315403808148"
                        frameborder="0"
                        style={{ width: "100%", height: "600px" }}
                        scrolling="no"
                    />
                </div>
            </div>
        );
    }
}

export default LicensingQuote;
