import React, { Component } from "react";
import { Col, Row } from "react-materialize";

class Payment extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <div>
                        <h1 className="title">Make A Payment</h1>
                        <div className="pageTextMarg">
                            <p className="flow-text">
                                We offer several ways for you to pay and keep your account active. Enter your payment
                                information below for fast, easy renewals.
                                <br />
                                Note: If you are a new client please visit our store.
                            </p>
                        </div>
                    </div>
                    <br />
                    <iframe
                        id="JotFormIFrame-80295798308167"
                        allowtransparency="false"
                        allowfullscreen="true"
                        src="https://form.jotform.com/80295798308167"
                        style={{ width: "100%", height: "600px" }}
                        frameborder="0"
                        scrolling="no"
                    />
                </div>
            </div>
        );
    }
}

export default Payment;
