import React, { Component } from "react";

class Contact extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <div className=" contactTextCont">
                        <h1 className=" title">Contact Us</h1>
                        <p className=" flow-text">
                            6717 N. Black Canyon Highway
                            <br />
                            Phoenix, AZ 85015
                            <br />
                            Office: (602) 242-2222
                            <br />
                            Fax: (602) 433-1219
                            <br />
                            e-mail: Info@getlicensedbondedinsured.com
                        </p>
                        <iframe
                            title="Google Map"
                            width="80%"
                            height="450"
                            frameborder="0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.6841831324327!2d-112.11394028492091!3d33.53559585222633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b6cc435e59da3%3A0x6bdfa20143e4fba!2s6717+N+Black+Canyon+Hwy%2C+Phoenix%2C+AZ+85015!5e0!3m2!1sen!2sus!4v1496342588804"
                            allowfullscreen
                        />
                        <iframe
                            id="JotFormIFrame-80115705808151"
                            onload="window.parent.scrollTo(0,0)"
                            allowtransparency="false"
                            allowfullscreen="true"
                            src="https://form.jotform.com/80115705808151"
                            frameborder="0"
                            style={{ width: "100%", height: "600px" }}
                            scrolling="no"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
