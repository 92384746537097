import React from "react";
import { Button } from "react-materialize";

class Warning extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showWarning: true };
    }

    handleWarning = e => {
        e.preventDefault();
        this.setState({ showWarning: false });
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div
                        className="col s12"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 15,
                            marginBottom: 0,
                        }}
                    >
                        <div
                            id="card-alert"
                            className="card yellow lighten-2 "
                            style={{
                                display: this.state.showWarning ? "" : "none",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    flat
                                    onClick={e => this.handleWarning(e)}
                                    style={{
                                        color: "black",
                                    }}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">X</span>
                                </Button>
                            </div>
                            <div
                                className="card-content black-text darken-1 flow-text"
                                style={{
                                    paddingTop: 0,
                                    fontSize: 14,
                                }}
                            >
                                <div>
                                    <span className="card-title black-text darken-1">Important Information</span>
                                    <p>
                                        Our first priority at ACLS, Inc is the safety of our staff and our customers.
                                        During these events surrounding the COVID-19 situation, we encourage you to do
                                        all that is necessary to remain safe and healthy.
                                    </p>
                                    <br />
                                    <p>
                                        In response to the growing concerns, we want to reassure you that we are
                                        operational and ready to provide you with superior customer service. Our staff
                                        is working remotely and remain ready to take your calls, emails and chats
                                        regarding Studies, Paperwork, Bonds and Insurance.{" "}
                                    </p>
                                    <br />
                                    <p>
                                        Important Contacts: <br /> Office Phone: (602) 242-2222 <br /> Online Studies
                                        email: nancy@lbiaz.com <br /> Licensing email: megan@lbiaz.com <br /> Bonds
                                        email: mj@lbiaz.com
                                        <br /> Insurance email: deisy@lbiaz.com{" "}
                                    </p>
                                    <p>
                                        <br /> As usual, you may submit your payments, inquiries and new registrations
                                        through our website. In this challenging time we are committed to helping you
                                        maintain your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Warning;
