import React, { Component } from "react";
import { Link } from "react-router-dom";

class Licensed extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <h1 className="title">How to Get An Arizona Contractors License</h1>
                    <div className="pageTextMarg">
                        <p className="flow-text ">
                            The process of getting an Arizona Contractors License begins with the understanding that all
                            persons working in the Arizona construction industry are required by law to obtain a current
                            and active license. Much like a driver's license, the Arizona Contractors License grants
                            legal status to perform some skill and/or trade.
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="flow-text">
                            At GetLicensedBondedInsured.com we have developed a computer based licensing system. We fill
                            out and process all of the paperwork required to obtain the Arizona Contractors License. You
                            provide the information, we provide the results. In licensing, our staff must work hand in
                            hand with the Arizona Department of Revenue, the Arizona Corporation Commission, the Arizona
                            Secretary of State, the Internal Revenue Service and the Arizona Registrar of Contractors.
                            Our staff hand delivers all applications to the State as required. We do it all!! Following
                            are the basic steps in the licensing process.
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="licenseStep">Step 1: Work Experience</p>
                        <p className="flow-text">
                            The Arizona Registrar of Contractors REQUIRES actual on-the-job training experience to
                            verify a work history for any contractor license applicant. Most trades require 2 to 4 years
                            of full time, journeyman level experience. They are then attached to the contractor license
                            application prior to submission. Falsification of ANY INFORMATION is a FELONY!! Click{" "}
                            <Link className="companySetupLinks" to="licensed/licenseclassifications">
                                here
                            </Link>{" "}
                            for work experience and testing requirements.
                        </p>
                    </div>
                    <br />
                    <div>
                        <p className="licenseStep">Step 2: Studying & Testing</p>
                        <p className="flow-text">
                            <Link to="/licensed/testprep" className="companySetupLinks">
                                CLICK HERE FOR STUDY COURSE INFORMATION
                            </Link>
                            <br />
                            <div className="pageTextMarg">
                                All contractors are required to take and pass a Business Management Exam. This test is
                                composed of 80 randomly selected test questions at the state testing authority, PSI
                                Testing. We offer a complete test preparation for this exam. Clients study at their own
                                pace and schedule. Computer based test review speeds up the learning process. Studying
                                can be done in office and/or online. Many licenses require the qualifying party to pass
                                a trade test. Our trade preparation courses are all inclusive with NO BOOKS to BUY! This
                                saves you hundreds of dollars. All books recommended by the testing company are
                                available to use in our office as often as you wish. You are also able to check the
                                books out to take into your actual test with you. In this, we are very unique. Passing
                                the test(s) will NOT make you legal in the State of Arizona. Passing scores are only one
                                of the requirements needing to be completed BEFORE applying for the license. Arizona
                                does NOT have a "License Pending" exemption.
                            </div>
                        </p>
                    </div>
                    <br />
                    <div className="pageTextMarg">
                        <p className="licenseStep">Step 3: Company Setup & Application Processing</p>
                        <p className="flow-text">
                            In Arizona, the license is not issued to any one individual. The license is actually issued
                            to the COMPANY. Therein, a Company has to be created and approved by state agencies before
                            it can be used by the business. We can help you through the ENTIRE Company Set-up. Give us a
                            few names you're considering for your business and let us get started. We will setup your
                            Sole Proprietor, Partnership, Limited Liability Company (LLC) or Corporation to simplify
                            Licensing. We will save you time and money!!
                            <br />
                            Click{" "}
                            <Link to="/licensed/companysetup" className="companySetupLinks">
                                here
                            </Link>{" "}
                            to learn about the differences in company structures.
                        </p>
                    </div>
                    <br />
                    <Link to="/store/licensingquote" className="flow-text companySetupLinks">
                        TO GET A QUOTE OR GET STARTED ON THE LICENSING PAPERWORK CLICK HERE
                    </Link>
                </div>
            </div>
        );
    }
}

export default Licensed;
