import React from "react";
import NewLogoWebPCropped from "../Assets/Images/backgroundsAndPageImages/NewLogoWebPCropped.webp";

class Logo extends React.Component {
    render() {
        return (
            <div>
                <img className="getLicensedLogo" alt="Get licensed bonded insured" src={NewLogoWebPCropped} />
            </div>
        );
    }
}

export default Logo;
