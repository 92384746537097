import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-materialize";

import AppointmentOnly from "../AppointmentOnly/AppointmentOnly";

class HomePage extends Component {
    render() {
        return (
            <div className="outerCont">
                <div className="main">
                    <div className="howToBlock">
                        <h1 className="title">How to get Licensed Bonded Insured</h1>
                        <p className="flow-text">
                            Getting your Arizona contractors license requires several steps such as test preparation,
                            application processing, company setup, bonds, and insurance.To simplify this process and
                            save you money, GetLicensedBondedInsured.com provides all of these services in one location
                            and online.No running around, No games, No gimmicks. <br />
                            <Link to="/store" className="companySetupLinks">
                                <h4>Click here to get started today!</h4>
                            </Link>
                        </p>
                    </div>
                    <br />
                    <Row>
                        <Col s={12} m={12} l={12} id="">
                            <iframe
                                style={{
                                    width: "90%",
                                    maxWidth: "900px",
                                    height: "38vh",
                                    minHeight: "350px",
                                }}
                                src="https://www.youtube.com/embed/7QaV0VGPR3U?rel=0;&autoplay=1&mute=1"
                                frameborder="0"
                                allow="accelerometer; autoplay=true; mute=true; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            />
                        </Col>
                    </Row>
                    <div className="numOneInIndBlock">
                        <h5 className="numOneTitle">Number 1 in our industry for:</h5>
                        <p className="flow-text">
                            <p className="flow-text">Online Contractor Test Preparation</p>
                            <p className="flow-text">Complete Company Setups - LLC or Corporations</p>
                            <p className="flow-text">Arizona Contractors Bonds</p>
                            <p className="flow-text">Arizona Contractors Insurance</p>
                            <p className="flow-text">Business Consulting</p>
                            <p className="flow-text"> Customer Service </p>
                        </p>
                    </div>
                    <div className="homeThirdBlock">
                        <p className="flow-text">
                            We are certified as Legal Document Preparers(81244) specializing in all processes necessary
                            to get your contracting business started. Don't worry about having to figure out and submit
                            forms to the
                            <a
                                className="links"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.azdor.gov/"
                            >
                                <span> Arizona Department of Revenue</span>
                            </a>
                            ,
                            <a className="links" target="_blank" rel="noopener noreferrer" href="http://www.azcc.gov/">
                                <span> Arizona Corporation Commission</span>
                            </a>
                            , the
                            <a
                                className="links"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.azsos.gov/"
                            >
                                <span> Secretary of State</span>
                            </a>
                            , the
                            <a className="links" target="_blank" rel="noopener noreferrer" href="https://www.irs.gov/">
                                <span> IRS </span>
                            </a>
                            <span>or the </span>
                            <a className="links" target="_blank" rel="noopener noreferrer" href="https://roc.az.gov/">
                                Arizona Registrar of Contractors
                            </a>
                            <span>
                                . We will do all the paperwork for you at the lowest price in town. With our proven
                                results and high referral rate, you can't go wrong! Call us today!
                            </span>
                        </p>
                    </div>
                    <br />
                </div>
                <div />
            </div>
        );
    }
}

export default HomePage;
