import Bruce2019WebPCropped from "../../Assets/Images/People/Bruce2019WebPCropped.webp";
import Deisy2019WebPCropped from "../../Assets/Images/People/Deisy2019WebPCropped.webp";
import Mackenzie2019WebPCropped from "../../Assets/Images/People/Mackenzie2019WebPCropped.webp";
import Megan2019WebPCropped from "../../Assets/Images/People/Megan2019WebPCropped.webp";
import Nancy2019WebPCropped from "../../Assets/Images/People/Nancy2019WebPCropped.webp";

export const people = [
    {
        name: "Bruce Evers",
        pic: {
            jpeg: "Bruce2019.jpeg",
            webP: Bruce2019WebPCropped,
        },
        jobTitle: "Founder, COB MBA, BsEd, Senior Professional Medical Representative",
        description: `Bruce Evers has the credentials to make
        GetLicensedBondedInsured.com different than the other "schools".He has a bachelors degree in secondary education.
        Mr.Evers obtained his computer based MBA in 1991,
        graduating from the University of Phoenix in 18 months with
        a ranking of first place in his graduating class(4.0 GPA),
        receiving the Faculty Academic Award.Bruce has been a small
        business development consultant and strategist
        for over 28
        years.He has helped many small business owners succeed.He
        is a licensed agent
        for Property / Casualty,
        Health,
        Accidental and Life insurance as well as a certified Legal
        Document Preparer with the Arizona Supreme Court.Bruce also
        started working in the construction industry in 1967
        qualifying him to not only assist you in becoming a
        successful businessman but also answering any questions you
        may have in your trade and in your exam preparation.`,
        classRef: `bruceProfilePic`,
    },
    {
        name: "Deisy Perez",
        pic: {
            jpeg: "Deisy2019.jpeg",
            webP: Deisy2019WebPCropped,
        },
        jobTitle: "Insurance",
        description: `Always dedicated to the success of our clients.
        Knowledgeable in the process of Contractor Licensing and
        blending her experience in the business of construction.
        Specializing in Insurance and Bonds. She is excellent in
        developing rapport with clients, companies and government
        agencies. If you need help with Bonds, General liability,
        Auto and Worker's Compensation, Deisy is your contact.
        Certified Legal Document Preparer and licensed in
        Property/Casualty. Se Habla Espanol.`,
        classRef: `profilePic`,
    },
    {
        name: "Mackenzie Johnson",
        pic: {
            jpeg: "Mackenzie2019.jpeg",
            webP: Mackenzie2019WebPCropped,
        },
        jobTitle: "Bonds",
        description: `Providing you with a smiling face, she is our
        Go To  for instant issue bonds and all renewals including: bonds, 
        Annual reports, Statutory Agent, and Licensing. Not only does she 
        make the process easy and simple, but you can expect fast and 
        accurate service.`,
        classRef: `profilePic`,
    },
    {
        name: "Megan Lopez",
        pic: {
            jpeg: "Megan2019.jpeg",
            webP: Megan2019WebPCropped,
        },
        jobTitle: "Licensing",
        description: `Your contact on Company Set-Up and Licensing. Guiding our clients through each 
        step of the process, that includes: Registering your Corporation/LLC, obtaining tax numbers, 
        completing license applications and hand delivering to every Government Agency. Megan puts 
        in the time and care to guarantee our clients success.`,
        classRef: `profilePic`,
    },
    {
        name: "Nancy Martinez",
        pic: {
            jpeg: "Nancy2019.jpeg",
            webP: Nancy2019WebPCropped,
        },
        jobTitle: "Client Services",
        description: `The first person you see when you walk in the door and
        most likely the first person you talk to when you call in.
        Not only can Nancy help you with your initial licensing
        questions, she will be there to help ensure you have the
        accurate material needed for the state exam. From test
        scheduling to book reservations and rentals, Nancy's your
        girl! Se Habla Espanol`,
        classRef: `profilePic`,
    },
];
